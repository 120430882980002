<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header pa-0 mt-3">
        <h1>My Workers' Compensation Insurance</h1>
      </div>
      <div class="dashboard__container--body mb-5" style="width:auto;">
      	<div class="dashboard__container--body--col">
      		<div class="mt-3 flex flex-column">
						<p>In the event of an accident during on the job with Jump, here's what you need to do: </p>
						<p>1 - Seek treatment for medical emergencies. Please got to the nearest urgent care or hospital in the event of a medical emergency</p> 
						<p>2 - Contact support at support@jumpstaffing.com to let us know you've been injured and let us know if you have questions about your options and insurance coverage. You will be provided with a form to complete. After the form is submitted, you will receive a call from an insurance agent within 48 hours of submission.</p>
					</div>
					<h3>Policy Details</h3>
      		<p>In every state EXCEPT Wisconsin, your insurance carrier is SUNZ Insurance Group.</p>
					<p>Your policy number is #WC015-00001-023.</p>
					<p>In Wisconsin, your insurance carrier is United Wisconsin Insurance Company. </p>
					<p>Your policy number is #WC515-00001-023-SZ.</p>
					<p>For all billing questions, call <a href="tel:505-212-6901">505-212-6901</a>.</p>
					<div class="mt-3 pa-3" style="background: white;">
						<h3>Find a Doctor</h3>
						<div class="mt-3 mb-3">
	            <label for="title">Choose State:</label>
	            <v-select
	            class="mt-1"
	              label="title" 
	              :options="states"
	              v-model="state"
	              :clearable="true"
	              >
	            </v-select>
	          </div>
	          <div class="mt-3" v-if="statePanels && statePanels.length >= 1">
          		<h5 class="mb-2">Options:</h5>
		          
	            <vue-good-table
	              :columns="columns"
	              :rows="statePanels"
	              >
	              <template slot="table-row" slot-scope="props">
	                <span v-if="props.column.field == 'url'">
	                  <a :href="props.row.url" target="_blank">View Available Doctors<i class="fas fa-external-link ml-2"></i></a>
	                </span>
	                <span v-else-if="props.column.field == 'created'">
	                  <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
	                </span>
	                <span v-else-if="props.column.field == 'state'">
	                  {{props.row.state}}
	                </span>
	                <span v-else-if="props.column.field == 'extras'">

	                    <i class="fas fa-trash" @click="deleteUploadedFile(props.row, props.index)"></i>
	    
	                </span>
	                <!-- <span v-else>
	                  {{props.formattedRow[props.column.field]}}
	                </span> -->
	              </template>
	            </vue-good-table>
		        
		        </div>

					</div>
      	</div>
      	<div class="dashboard__container--body--col">
      		<h3>Incident Forms</h3>
      		
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FAuthorization%20for%20Treatment%20SUNZ%20and%20UWIC%20(updated%208-1-23).pdf?alt=media&token=a3f39e48-f7fe-45e3-bab5-830799f58651" target="_blank">Authorization Form for Medical Servies</a></div>
      		<div class="caption">This form is your medical card.</div>


      		
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FInfiniti%20HR%20-%20First%20Report%20of%20Injury.pdf?alt=media&token=904a4a51-d945-4f71-ad46-3102dac27c3c" target="_blank">First Report of Injury</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FInfinitiHR%20-%20CA%20Rx%20Fill%20Letter%20(English-Spanish)%20effective%208.1.2022.pdf?alt=media&token=018c3a59-d580-455a-8488-1273c17a6b4a" target="_blank">Refusal of Medical Treatment</a></div>
      		<div class="mt-5"></div>
      		<h3>Presription Forms and Medical Cards</h3>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FInfiniti%20HR%20-%20First%20Report%20of%20Injury.pdf?alt=media&token=904a4a51-d945-4f71-ad46-3102dac27c3c" target="_blank">Workers’ Compensation First Fill Program</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FInfinitiHR%20-%20CA%20Rx%20Fill%20Letter%20(English-Spanish)%20effective%208.1.2022.pdf?alt=media&token=018c3a59-d580-455a-8488-1273c17a6b4a" target="_blank">California Prescription Letter</a></div>
      		<div class="mt-5"></div>
      		<h3>Posting Notices</h3>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FAZ%20Posting%20Notice.pdf?alt=media&token=bceb2b29-b142-4fe4-aad6-6d4f7883ee51" target="_blank">Arizona Posting Notice</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FCA%20Posting%20Notice.pdf?alt=media&token=66974d00-d899-4296-9a6c-5abb09dc83fc" target="_blank">California Posting Notice</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FCT%20Posting%20Notice.pdf?alt=media&token=7aa71482-836f-4155-b2ef-c2bc04a19795" target="_blank">Connecticut Posting Notice</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FDE%20Posting%20Notice.pdf?alt=media&token=3f39f8c6-3171-4102-b3e9-d48f0cd5d4c0" target="_blank">Delaware Posting Notice</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FGA%20Posting%20Notice.pdf?alt=media&token=56081262-6461-4664-b303-4395170ac346" target="_blank">Georgia Posting Notice</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FKY%20Posting%20Notice.pdf?alt=media&token=4ef96bc5-be3c-49c4-a4d2-8421b83e9af9" target="_blank">Kentucky Posting Notice</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FTN%20Posting%20Notice.pdf?alt=media&token=4cc4190d-ff9a-4d30-b2f6-65b68754db26" target="_blank">Tennessee Posting Notice</a></div>
      		<div class="mb-1"><a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2FTX%20Posting%20Notice.pdf?alt=media&token=4067eb96-0a2f-4c7b-85b7-47755be56413" target="_blank">Texas Posting Notice</a></div>
      	</div>

				
			</div>
		</div>
	</div>
</template>


<script>
import * as moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'wc',
  data: () => ({
    state: '',
    states: ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
    columns: [
      {
        label: 'Uploaded',
        field: 'created',
        sortable: false,
        width: '80px'
      },
      {
        label: 'State',
        field: 'state',
        width: '100px'
      },
      {
        label: 'Url',
        field: 'url',
        sortable: false,
        width: '180px'
      },
    ]
  }),
  computed: {
    ...mapState(['userProfile', 'currentUser', 'panels']),
    statePanels() {
    	return this.panels.filter(panel => {
    		return panel.state == this.state
    	})
    }
  },
  methods: {
  	formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('MM/DD/YYYY')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
  },
  created () {
    this.$store.dispatch("getPanels")   
  },
  beforeDestroy() {
    this.$store.dispatch("clearPanels")
  }
}
</script>